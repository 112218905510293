var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c("div", { staticClass: "filter-container" }),
      ]),
      _c("div", {
        staticClass: "app-container flex-item",
        attrs: { id: "txdituContainer" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }