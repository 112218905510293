<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <!-- <base-search :searchList="$cbglKzjlSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search> -->
      </div>
    </sticky>
    <div class="app-container flex-item" id="txdituContainer"></div>


  </div>

</template>

<script>
  import iconImg from '@/assets/img/Water.png'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          queryType: '0',
          cxnr: '1',
          key: undefined
        },
        iconImgUrl:iconImg,
        tipDatas:[],//标点数据
        centerLatitude:'39.928',//中心点纬度
        centerLongitude:'116.404',//中心点经度
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {

    },
    async mounted() {
      this.getMapData()//获取地图数据
      // this.initMap()//初始化地图
    },
    methods: {
      getMapData(){
        basicDataApi.dtzsGetList().then(response => {
          if(response.code == 200){
            this.centerLatitude = response.result.latitude
            this.centerLongitude = response.result.longitude
            this.tipDatas = response.result.tipDatas
            this.initMap()//初始化地图
          }else{
            
          }
        }).catch(()=>{
          
        })
      },
      //初始化地图
      initMap(){

        // 标记点数组
        // var tipDatas=[
        //   {
        //     id: 1,//必须,标点唯一id
        //     content: '张家庄小区',//标点名称
        //     latitude: '39.925',//标点坐标 - 纬度
        //     longitude: '116.404',//标点坐标 - 经度
        //     tipsContent:'张家庄小区#号楼#单元102',//标点悬浮窗标题
        //   },
        //   {
        //     id: 2,
        //     content: '李家庄小区',
        //     latitude: '39.915',
        //     longitude: '116.404',
        //     tipsContent:'李家庄小区4号楼2单元1202',
        //   },
        // ]


        for (let i = 0; i < this.tipDatas.length; i++) {
          this.tipDatas[i].styleId = 'myStyle';//标点使用的样式
          this.tipDatas[i].position = new TMap.LatLng(this.tipDatas[i].latitude,this.tipDatas[i].longitude);//标点坐标
          this.tipDatas[i].content = this.tipDatas[i].content;//标点名称
          this.tipDatas[i].properties = {
            title:this.tipDatas[i].tipsContent,// 标点悬浮窗标题
          }
        }
        
        var center = new TMap.LatLng(this.centerLatitude,this.centerLongitude);//设置中心点坐标
        //初始化地图
        var map = new TMap.Map(txdituContainer, {
          center: center
        });
        


        //创建点聚合对象
        var markerCluster = new TMap.MarkerCluster({
            id: 'cluster', //图层id
            map: map,       //设置点聚合显示在哪个map对象中（创建map的段落省略）
            enableDefaultStyle: true,   //使用默认样式
            minimumClusterSize: 2,  //最小聚合点数：2个
            geometries: this.tipDatas,
            zoomOnClick: true,  //点击聚合数字放大展开
            gridSize: 60,       //聚合算法的可聚合距离，即距离小于该值的点会聚合至一起，默认为60，以像素为单位
            averageCenter: false, //每个聚和簇的中心是否应该是聚类中所有标记的平均值
            maxZoom: 16 //采用聚合策略的最大缩放级别，若地图缩放级别大于该值，则不进行聚合，标点将全部被展开
        });


        // //创建并初始化MultiMarker
        // var marker = new TMap.MultiMarker({
        //   map: map,  //指定地图容器
        //   //样式定义
        //   styles: {
        //     //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
        //     "myStyle": new TMap.MarkerStyle({ 
        //       "width": 35,  // 点标记样式宽度（像素）
        //       "height": 35, // 点标记样式高度（像素）
        //       "src": this.iconImgUrl,  //图片路径
        //       //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
        //       "anchor": { x: 16, y: 32 },
        //       direction: 'bottom', // 标注点文本文字相对于标注点图片的方位
        //       offset: { x: 0, y: 8 }, // 标注点文本文字基于direction方位的偏移属性
        //     }) 
        //   },
        //   //点标记数据数组
        //   geometries: this.tipDatas
        //   // [
        //   //   {
        //   //     "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
        //   //     "styleId": 'myStyle',  //指定样式id
        //   //     "position": new TMap.LatLng(39.925, 116.404),  //点标记坐标位置
        //   //     'content': '张家庄小区', //标注文本
        //   //     "properties": {//自定义属性
        //   //       "title": "张家庄小区"
        //   //     }
        //   //   }, 
        //   //   {//第二个点标记
        //   //     "id": "2",
        //   //     "styleId": 'myStyle',
        //   //     "position": new TMap.LatLng(39.915, 116.404),
        //   //     'content': '桃李小区', //标注文本
        //   //     "properties": {
        //   //       "title": "桃李小区"
        //   //     }
        //   //   }
        //   // ]
        // });
        // //初始化infoWindow
        var infoWindow = new TMap.InfoWindow({
          map: map,
          position: new TMap.LatLng(26.223556, 107.520795),
          offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
        });
        infoWindow.close();//初始关闭信息窗关闭

        // url处理
        var allUrl = window.location.href
        const index = allUrl.lastIndexOf("/#/")
        const url = allUrl.substring(0, index+3) + 'hdybcbxx/index'
        // 跳转抄表信息页面
        
        //监听标注点击事件 - 带跳转
        markerCluster.on("click", function (evt) {
          //设置infoWindow
          infoWindow.open(); //打开信息窗
          infoWindow.setPosition(evt.cluster.geometries[0].position);//设置信息窗位置
          infoWindow.setContent(`<div style="white-space:nowrap;margin:0 10px;color:#000;text-align:left;">
            <p style="font-size:16px;margin-bottom:10px;margin-top:0px;"><a href="${url}?id=${evt.cluster.geometries[0].id}" style="color:#1a96d4">${evt.cluster.geometries[0].content}<a/></p>
            <p style="font-size:14px;margin-top:5px;margin-bottom:10px;color:#b4b4b4;">${evt.cluster.geometries[0].tipsContent}</p>
            <p style="font-size:16px;margin-bottom:10px;margin-top:0px;">绑定设备数量：${evt.cluster.geometries[0].meterCount}</p>
            <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;">区域负责人编号：${evt.cluster.geometries[0].areaPersonNum}</p>
            </div>`);//设置信息窗内容
        })



        // //监听标注点击事件
        // markerCluster.on("click", function (evt) {
        //   //设置infoWindow
        //   infoWindow.open(); //打开信息窗
        //   infoWindow.setPosition(evt.cluster.geometries[0].position);//设置信息窗位置
        //   infoWindow.setContent(`<div style="white-space:nowrap;margin:0 10px;color:#000;text-align:left;">
        //     <p style="font-size:16px;margin-bottom:10px;margin-top:0px;">${evt.cluster.geometries[0].content}</p>
        //     <p style="font-size:14px;margin-top:5px;margin-bottom:10px;color:#b4b4b4;">${evt.cluster.geometries[0].tipsContent}</p>
        //     <p style="font-size:16px;margin-bottom:10px;margin-top:0px;">绑定设备数量：${evt.cluster.geometries[0].meterCount}</p>
        //     <p style="font-size:14px;margin-top:5px;margin-bottom:0px;color:#b4b4b4;">区域负责人编号：${evt.cluster.geometries[0].areaPersonNum}</p>
        //     </div>`);//设置信息窗内容
        // })

      },
    },
  }

</script>

<style lang="scss" scoped>
  #txdituContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: "微软雅黑";
  }
</style>
